import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MortgageCalculator from "../pages/mortgage-calculator";
import EntitlementCalculator from "../pages/entitlement-calculator";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/mortgage" />} />
          <Route path="/mortgage" element={<MortgageCalculator />} />
          <Route path="/rent-vs-own" element={<EntitlementCalculator />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
