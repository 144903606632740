import React from 'react';
import "./index.css"

const EntitlementCalculator = () =>{
  return(
      <div className='entitlement-calculator-wrapper'><iframe className='entitlement-calculator' height="100%" width="100%" title='EntitlementCalculator' id="KJE-IFRAME" src='./entitlementCalculator.html'></iframe></div>
  );
}

export default EntitlementCalculator;
