import React from "react";
import { useLocation } from 'react-router-dom';
import "./index.css";

const MortgageCalculator = () => {
 const {search} = useLocation();
 
  return (
    <div className="mortgage-calculator-wrapper">
      <iframe
        className="mortgage-calculator"
        height="100%"
        width="100%"
        data-url={process.env.REACT_APP_API_URL}
        title="MortgageCalculator"
        id="Mortgage-IFRAME"
        src={"./mortgageCalculator.html" + search}
      ></iframe>
    </div>
  );
};

export default MortgageCalculator;
